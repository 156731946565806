import styled from "styled-components";
import Headline from "./copys/Headline";
import AvatarPortrait from "./AvatarPortrait";
import Link from "./Link";

export default ({ tag, name, customization }) => {
  return (
    //TBD: link
    <StyledLink to={`/profile/${tag}`}>
      <Wrapper>
        {customization ? (
          <AvatarBackground>
            <StyledAvatarPortrait customization={customization} />
          </AvatarBackground>
        ) : null}
        <Copys>
          <Headline size="xs">{name}</Headline>
        </Copys>
      </Wrapper>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  width: 100%;
`

const Wrapper = styled.div`
  border-radius: ${(p) => p.theme.radius[400]};
  border: ${(p) => p.theme.stroke[400]} solid ${(p) => p.theme.grayscale[200]};
  padding: 0.25rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 4rem;
`;
const Copys = styled.div``;

const AvatarBackground = styled.div`
  height: 100%;
  width: auto;
  aspect-ratio: 1/1;
  background: ${(p) => p.theme.grayscale[200]};
  border-radius: ${(p) => p.theme.radius[300]};
  overflow: hidden;
`;

const StyledAvatarPortrait = styled(AvatarPortrait)`
  width: 100%;
  height: 100%;
`;
