import { useInterfaceStore } from "../../stores/useInterfaceStore";
import { useUserStore } from "../../stores/useUserStore";
import Button from "../../components/Button";
import { t } from "../../lang/index.js";
import styled from "styled-components";
import Header from "../../components/Header";
import BalanceCount from "../../components/BalanceCount";
import { useShallow } from "zustand/react/shallow";
import CogIcon from "../../components/icons/CogStroke";
import ButtonIcon from "../../components/ButtonIcon";
import NavBarLine from "../../components/NavBarLine";
import ProfilePreview from "../../components/ProfilePreview";
import GameCardSlider from "../../components/game/GameCardSlider";
import games from "../../../games";
import Link from "../../components/Link";

export default () => {
  const [tag, gems, coins, name, customization] = useUserStore(
    useShallow((s) => [s.tag, s.gems, s.coins, s.name, s.customization]),
  );

  return (
    <>
      <NavBarLine />
      <Wrapper>
        <Header>
          <HeaderContent>
            {tag ? (
              <HeaderBalances>
                <BalanceCount type="gem" count={gems} />
                <BalanceCount type="coin" count={coins} />
              </HeaderBalances>
            ) : (
              <Button size="sm" onClick={() => useInterfaceStore.setState({ signUpVisible: true })}>
                Sign Up
              </Button>
            )}
            <Link to="/settings">
              <ButtonIconSettings>
                <CogIcon />
              </ButtonIconSettings>
            </Link>
          </HeaderContent>
        </Header>
        {tag ? <ProfilePreview tag={tag} name={name} customization={customization} /> : null}
        <GameCardSlider slugs={Object.keys(games)} headline={t("games")} link={{ copy: "All games", to: "/games" }} />
      </Wrapper>
    </>
  );
};

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 0.5rem;
`;

const ButtonIconSettings = styled(ButtonIcon)`
  color: ${(p) => p.theme.text[500]};
`;

const HeaderBalances = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  gap: ${(p) => p.theme.gap.section[500]};
  flex-direction: column;
`;
