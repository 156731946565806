import styled from "styled-components";
import { renderer } from "~/webgl/renderer";
import { Scene, PerspectiveCamera } from "three";
import { useEffect, useRef, useState } from "react";
import sizes from "../utils/sizes";
import Avatar from "../webgl/shared/avatar/Avatar";
import resources from "../utils/resources";
import { init as initWebGL } from "~/webgl";

export default ({ customization = { skin: "10000" }, size = 256, quality = "high", ...rest }) => {
  const scene = useRef(new Scene());
  const camera = useRef(new PerspectiveCamera(45, 1, 0.1, 1000));
  const avatar = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [resourcesReady, setResourcesReady] = useState(false);

  const render = () => {
    initWebGL();
    renderer.setSize(size, size);

    camera.current.position.set(0, 1.5, 1.8);

    if (!avatar.current) {
      avatar.current = new Avatar({ customization, quality });
      scene.current.add(avatar.current.mesh);
    }

    renderer.render(scene.current, camera.current);

    setImageData(renderer.domElement.toDataURL("image/png"));

    renderer.setSize(sizes.width, sizes.height);
  };

  const handleResourcesReady = () => {
    if (!resourcesReady) setResourcesReady(true);
  };

  useEffect(() => {
    if (resources.isReady) render();
    else resources.once("ready", handleResourcesReady);

    return () => {
      resources.off("ready", handleResourcesReady);

      if (avatar.current) {
        avatar.current.destroy();
        scene.current.remove(avatar.current.mesh);
        avatar.current = null;
      }
    };
  }, [customization, size, quality, resourcesReady]);

  return <Wrapper {...rest}>{imageData && <img src={imageData} alt="Avatar Portrait" />}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
